<template>
  <div>
    <coal-products></coal-products>
  </div>
</template>

<script>
import CoalProducts from '../components/CoalProducts.vue'

export default {
  name: "Coal",
  components: { CoalProducts },

}
</script>
